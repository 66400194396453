import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import ScrollToTop from "./helpers/ScrollToTop";
import { TransverseLoading } from "react-loadingg";
import * as ROUTES from "./routes";
import ReactGa from "react-ga";
import TagManager from "react-gtm-module";

const HomeTwo = lazy(() => import("./pages/HomeTwo"));
const About = lazy(() => import("./pages/About"));
const Blog = lazy(() => import("./pages/Blog"));
const BlogPost = lazy(() => import("./pages/BlogPost-1"));
const BlogPost2 = lazy(() => import("./pages/BlogPost-2"));
const Contact = lazy(() => import("./pages/Contact"));
const NotFound = lazy(() => import("./pages/NotFound"));

library.add(fab);

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-THV7BS7",
  };

  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    ReactGa.initialize("UA-176408204-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  const renderLoader = () => <TransverseLoading />;

  return (
    <Suspense fallback={renderLoader()}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path={ROUTES.MAIN}>
              <HomeTwo />
            </Route>
            <Route path={ROUTES.PORTFOLIO}>
              <About />
            </Route>
            <Route path={ROUTES.BLOG}>
              <Blog />
            </Route>
            <Route path={ROUTES.POST1}>
              <BlogPost />
            </Route>
            <Route path={ROUTES.POST2}>
              <BlogPost2 />
            </Route>
            <Route path={ROUTES.CONTACT}>
              <Contact />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </Suspense>
  );
}

export default App;
