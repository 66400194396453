import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    lng: "pl",
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    }
  });

export default i18n;